import React from "react";
import CustomDataTableController from "./CustomDataTableController";
import { 
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  Container,
  Grid,
  MenuItem,
  Paper, 
  Select, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography, 
  styled
} from "@material-ui/core";
import { count } from "console";
import { editIcon, deleteIcon } from "../../blocks/appointmentmanagement/src/assets";



class CustomDataTable extends CustomDataTableController {
  selectOptionMenu = (content: any) => {
    return (
      <div style={{ position: 'absolute' }}>
        <EditOptionModalStyle>
          <React.Fragment>
            <Container className="container">
              <Grid item xs={12} >
                <Box style={{ display: "flex", cursor: 'pointer' }}
                  data-test-id="editbutton">
                
                  <Typography
                    id="studyVal"
                    style={{
                      color: "#FFF",
                      fontFamily: "Jost",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600, marginLeft: '10px'
                    }}

                  >
                    {content}
                  </Typography></Box>
               

              </Grid>
            </Container>
          </React.Fragment>

        </EditOptionModalStyle>
      </div>
    )
  }
  render () {
    return (
      <Box>
        <TableContainer component={Paper} style={{ marginBottom: 20 }}>
        <Box style={{ overflowX: 'auto',maxWidth: 1500 }}>
          <Table style={{ minWidth: 700 }} aria-label="customized table">
            <TableHead style={{
              backgroundColor: "#EFEFEF",
              borderRadius: "8px",
            }}>
              <TableRow>
                {
                  this.props.tableData.columns.map((column, index) => {
                    return <TableCell key={index} align="left" 
                    onMouseEnter={()=>this.handleMouseEnter(column,index)}
                    onMouseLeave={this.handleMouseLeave}  
                    style={{
                      position: (column?.name === 'Patient Name' || column?.name === 'ID') ? 'sticky' : 'static',
                      left: column?.name === 'Patient Name' ? 0 : column?.name === 'ID' ? 131 : 'auto',
                      zIndex: (column?.name === 'Patient Name' || column?.name === 'ID') ? 1 : 'auto',
                      backgroundColor: 'white',
                      minWidth: (column?.name === 'Patient Name' || column?.name === 'ID') ? '100px' : '', 
                    }}
                    >                                          
                      <Typography style={{ fontWeight: "bold"}}>{column?.name}</Typography>
                      {this.state.hoverData && this.state.indexValue===index &&
                      (
                     this.selectOptionMenu(this.state.hoverData)
                )} 
                    </TableCell>
                    
                 
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.paginatedRows?.rows.map((row: any, index: number) => (
            
                <StyledTableRow key={index}>
                  {Object.keys(row).filter((valKey)=>(valKey!=='quickBlox'&&valKey!=='email')).map((key, index) => {
                   return <TableCell key={index} align="left"
                    style={{
                      position: (key === 'Patient Name' || key === 'ID') ? 'sticky' : 'static',
                      left: key === 'Patient Name' ? 0 : key === 'ID' ? 131 : 'auto',
                      zIndex: (key === 'Patient Name' || key === 'ID') ? 1 : 'auto',
                      backgroundColor: 'white'
                    }}
                  >
                    {row[key]}</TableCell>
                  })}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        </TableContainer>
       
        
          <Grid container style={styles.mainGrid}>
            <Grid>
            <ButtonGroup style={{ backgroundColor: "white" }}>
              {
                this.state.paginatedRows?.hasPrevPage &&
                <Button onClick={() => this.handleChangePage(this.state.paginatedRows?.prevPage as number)}>
                  <Typography style={{
                    color: "#A046FB"
                  }}>
                    {`<`}
                  </Typography>
                </Button>
              }
              {
                this.state.paginatedRows?.chunkedPages[this.findChunkIndex(this.state.paginatedRows?.chunkedPages, this.state.page)]
                  .map((item) => {
                    return (
                      <Button
                        onClick={() => this.handleChangePage(item)}
                        style={{ backgroundColor: this.state.page === item ? "#A046FB" : "white" }}
                      >
                        <Typography style={{
                          color: this.state.page === item ? "white" : "#A046FB",
                          fontWeight: this.state.page === item ? "bold" : "normal"
                        }}>{item}</Typography>
                      </Button>
                    );
                  })
              }
              {
                this.state.paginatedRows?.hasNextPage &&
                <Button onClick={() => this.handleChangePage(this.state.paginatedRows?.nextPage as number)}>
                  <Typography style={{
                    color: "#A046FB"
                  }}>
                    {`>`}
                  </Typography>
                </Button>
              }
            </ButtonGroup>
            </Grid>
            <Grid>
            <Select
              value={this.state.limit}
              onChange={this.handleChangeLimit}
              style={styles.selectMenu}
            >
              <MenuItem value={10}>10 items per page</MenuItem>
              <MenuItem value={25}>25 items per page</MenuItem>
              <MenuItem value={50}>50 items per page</MenuItem>
              <MenuItem value={100}>100 items per page</MenuItem>
            </Select>
            </Grid>
          </Grid>

        </Box>
    );
  }
}

const styles = {
  mainGrid: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  selectMenu: {
    width: 200,
    backgroundColor: "white",
    borderRadius: "8px",
    border: "1px solid #EFEFEF",
    padding: "5px",
    borderBottom: 'none',
  }
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const EditOptionModalStyle = styled((Box as React.FC<BoxProps>))({

  "& .MuiOutlinedInput-input": {
    padding: 0,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    height: "38px",
    color: "#D4D3D3",
    fontFamily: "Jost",
    paddingLeft: '16px',
  }, "& .topBox": {
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .selectLogo": {
    verticalAlign: 'middle',
    float: 'right',
  },
  "& .searchStudy": {
    borderRadius: "4px",
    border: "1px solid #474747",
    height: "38px",
    cursor: 'pointer',
    color: "#D4D3D3",
    fontFamily: "Jost",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px"

  },
  "& .studyName.selected": {
    fontStyle: "normal",
    fontWeight: 600,
    color: "#FFF",
    fontFamily: "Jost",
    fontSize: "14px",
  },
  "& .studyName": {
    paddingTop: '16px',
    fontStyle: "normal",
    fontWeight: 400,
    cursor: 'pointer',
    color: "#D4D3D3",
    fontFamily: "Jost",
    fontSize: "14px",
  },
  "& .container": {
    background: "#2F2F2F",
    boxShadow: "none",
    position: "absolute",
    top: "10.4%",
    right: "18.4%",
    padding: '20px 30px',
    maxWidth: '700px',
    width: '100%',
    borderRadius: "8px",
    // "&::after": { // Create the pointer using ::after pseudo-element
    //   content: "''",
    //   position: "absolute",
    //   top: "-10px", // Adjust the positioning of the triangle
    //   right: "15px", // Adjust the positioning of the triangle
    //   width: "0",
    //   height: "0",
    //   borderLeft: "10px solid transparent",
    //   borderRight: "10px solid transparent",
    //   borderBottom: "10px solid #2F2F2F", // Set the color of the triangle to match the container background color
    // },
    "@media (max-width: 1366px)": {
      maxWidth: '500px',
      left: "auto",
      width: "auto",
    },
    "@media (max-width: 1199px)": {
      maxWidth: '400px',
      left: "auto",
      width: "auto",
    },
    "@media (max-width: 991px)": {
      maxWidth: '300px',
      left: "auto",
      width: "auto",
    },
    "@media (max-width: 691px)": {
      maxWidth: '200px',
      left: "auto",
      width: "auto",
    },
    "@media (max-height: 1366px)": {
      top: "auto",
      left: "auto",
      width: "auto",
    },
    "@media (max-height: 1199px)": {
      top: "auto",
      maxWidth: '174px',
      left: "auto",
      width: "auto",

    },
    "@media (max-height: 991px)": {
      top: "auto",
      left: "auto",
      width: "auto",
    },
    "@media (max-height: 600px)": {
      top: "auto",
    },
    "@media (max-height: 400px)": {
      top: "auto",
    },
    "@media (min-width: 1600px)": {
      // left: "16.8%",
      left: "auto",
      width: "auto",
    },
    "@media (min-width: 1920px)": {
      top: "auto",
      left: "auto",
      width: "auto",
    }
  },



})
export default CustomDataTable;