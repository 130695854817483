import React, { useRef } from 'react'
import { Button, Grid, MenuItem, Select, Slider, styled } from "@material-ui/core"
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';

export interface IIndividual {
    id: number,
    type: string,
    attributes: {
      task_name: string,
      description: string,
      study_id: number,
      milestone_id: number,
      published: boolean,
      total_points: number | null,
      question_types: IQuestion[],
      answers_by_account: IAnswersByAccount[]
    }
}

export interface IAnswersByAccount {
    // id: number,
    email: string,
    id_number: string,
    scored_points: number,
    answers: IAnswer[]
}

export interface IAnswer {
    answer: {
      id: number,
      account_id: number,
      question_type_id: number,
      answer_text: string,
      created_at: string,
      updated_at: string,
      range_value: string | null,
      task_list_id: number,
      media: string | null,
      media_urls: string[]
    },
    account: {
      id: number,
      full_name: string,
      email: string,
      id_number: string,
      scored_points: number
    }
  }

export interface IQuestion {
    id: number,
    type: "question_type",
    attributes: {
        question_type: string,
        question: string,
        select_answer_type: any,
        answer_media_type: any,
        options: IOption[],
        feedback: string,
        points: number,
        answer_media_quantity: number,
        minimum_value: any,
        maximum_value: any,
        question_media_type: string,
        is_image: any,
        is_video: any,
        image: any,
        subtype: any,
        minimum_answer_length: any,
        maximum_answer_length: any,
        video: any,
        selectedFileAttach?: any
    },
    answers: IAnswer[]
}

export interface IOption {
    id: number,
    option_name: string,
    question_type_id: number,
    is_correct: any,
    created_at: string,
    updated_at: string,
    isSelected?: boolean
}

interface Props {
    data: IQuestion,
    onOptionSelected?: (questionId: number, optionId: number) => void,
    answer?: any
}

export const Question = (props: Props) => {
    const fileInputRef = useRef<any>(null);
    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
            //   setImageDataUrl(reader.result);
            };
            reader.readAsDataURL(file);
        //   onFileChange(file);
        }
      };

    const onAttachImage = () => {
        fileInputRef.current?.click();
    }

    const renderQuestionContent = (data: IQuestion) => {
        if (data.attributes.question_type === "MCQ") {
            const awnsers = props.answer?.map((item: any) => item?.answer?.answer_text) || []
            let UncheckedBox = RadioButtonUncheckedIcon
            let ChekedBox = RadioButtonCheckedIcon
            if(data.attributes.select_answer_type === "multiple"){
                ChekedBox = CheckBoxOutlinedIcon
                UncheckedBox = CheckBoxOutlineBlankOutlinedIcon
            }
            return (
                <>
                    {
                        data.attributes.options.map(option => {
                            return (
                                <Grid
                                    item xs={12}
                                    key={`option-mcq-${option.id}`}
                                    style={{
                                        background: '#FFFFFF',
                                        borderRadius: 5,
                                        marginTop: 8,
                                        marginBottom: 8,
                                    }}>
                                    <Button
                                        disabled
                                        disableRipple
                                        style={{
                                            width: '100%',
                                            padding: 16,
                                            alignItems: 'flex-start',
                                            justifyContent: 'flex-start',
                                            textTransform: 'none',
                                        }}>
                                        {awnsers.includes(option.option_name) 
                                        ? <ChekedBox style={{color: "#A046FB"}} /> 
                                        : <UncheckedBox style={{ color: "rgba(192, 192, 192, 0.5)" }} />
                                        }
                                        <span style={{ flex: 1, marginLeft: 8, textAlign: 'left' }}>{option.option_name}</span>
                                    </Button>

                                </Grid>
                            )
                        })
                    }
                </>
            )
        }
        if (data.attributes.question_type == "Dropdown-Based") {
            const dropdownAnswer = props.answer?.[0]?.answer?.answer_text || ""
            return (
                <Grid
                    item xs={12}
                    key={`option-dropdown-based-${data.id}`}>
                    <StyledSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"       
                        value={dropdownAnswer}
                        disabled={props.answer ? true : false}
                        disableUnderline
                        MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                    >
                        <MenuItem value="" disabled>Select</MenuItem>
                        {
                            data.attributes.options.map(option => {
                                return (
                                    <MenuItemWithoutHover key={`dropdown-option-${option.id}`} value={option.option_name}>{option.option_name}</MenuItemWithoutHover>
                                )
                            })
                        }
                    </StyledSelect>
                </Grid>
            )
        }
        if (data.attributes.question_type == "Media-Based") {
            return (
                <Grid item xs={12} key={`option-media-based-${data.id}`}>
                    {data.attributes?.image?.url ? <Grid item xs={12} style={{ paddingBottom: 16, borderBottom: '1px solid rgba(192, 192, 192, 0.5)'}}>
                        <img style={{width: 125, height: 125}}
                            src={data.attributes.image.url}
                        />
                    </Grid> : null}
                    <Grid item xs={12} key={`option-${data.id}`} style={{marginTop: 16}}>
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            style={{display: 'none'}}
                            onChange={handleFileChange}
                        />
                        {
                            props.answer?.[0]?.answer?.media_urls ? 
                            props.answer?.[0]?.answer?.media_urls.map((url: string | undefined) => {
                                return <Grid item xs={12} key={`option-${url}`} style={{ paddingBottom: 16, borderBottom: '1px solid rgba(192, 192, 192, 0.5)'}}>
                                    <img style={{width: 125, height: 125}}
                                        src={url}
                                    />
                                </Grid> 
                            }) : null
                        }
                        {props.answer ? null : <Button
                            disabled
                            style={{ 
                                background: 'rgba(192, 192, 192, 0.3)', 
                                height: 100, 
                                width: '25%', 
                                borderRadius: 10,
                                textTransform: 'none'
                            }}>
                            <AttachFileIcon />
                            <span>Attach Image</span>
                        </Button>}
                    </Grid>
                </Grid>

            )
        }
        if (data.attributes.question_type == "Subjective") {
            return (
                <Grid
                    item xs={12}
                    key={`option-${data.id}`}>
                    <div style={{ padding: 16, borderRadius: 5, background: '#FFFFFF'}}>
                        <textarea
                            disabled={true}
                            // disabled={props.answer ? true: false}
                            value={props.answer?.[0]?.answer?.answer_text}
                            placeholder="Enter text here..."
                            rows={4} // Number of visible text lines
                            cols={100} // Number of visible text columns
                            style={{ 
                                resize: 'none',
                                borderRadius: 5,
                                border: '1px solid rgba(192, 192, 192, 0.5)',
                                width: '100%',
                                fontSize: 14,
                                fontFamily: 'Inter',
                                padding: 8
                            }}
                        />
                    </div>
                </Grid>
            )
        }
        if(data.attributes.question_type == "Range-Based"){
            if(data.attributes.subtype == "Progress-Bar-Range"){
                const currentValue = props.answer?.[0]?.answer?.range_value
                return(
                    <Grid item xs={12}
                        key={`option-${data.id}`}>
                        <NormalSlider
                            defaultValue={1}
                            // getAriaValueText={valuetext}
                            disabled={true}
                            value={currentValue}
                            aria-labelledby="discrete-slider-small-steps"
                            step={0.01}
                            min={Number(data.attributes.minimum_value)}
                            max={Number(data.attributes.maximum_value)}
                            valueLabelDisplay="auto"
                        />
                        <Grid item xs={12} style={{marginTop: 16}}>
                            <div style={{
                                display: 'flex',
                                width: '100%'
                            }}>
                                <span style={{flex: 1, textAlign: 'left'}}>Min</span>
                                <span style={{flex: 1, textAlign: 'right'}}>Max</span>
                            </div>
                        </Grid>
                    </Grid>
                )
            } else {
                const min = Number(data.attributes.minimum_value)
                const max = Number(data.attributes.maximum_value)
                const optionArray = []
                for(let i = min; i <= max; i++){
                    optionArray.push(Number(i).toFixed(0))
                }
                return(
                    <Grid item xs={12}
                        key={`option-${data.id}`}>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item style={{display: 'flex', flexDirection: 'column-reverse', paddingBottom: -6}}>
                                <span>Min</span>
                            </Grid>
                            {optionArray.map((value) => (
                                <Grid key={value} item>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <span style={{marginBottom: 16}}>{value}</span>
                                        {props.answer?.[0]?.answer?.range_value == value 
                                        ? <RadioButtonCheckedIcon style={{ color: "#A046FB" }} />
                                        : <RadioButtonUncheckedIcon style={{ color: "rgba(192, 192, 192, 0.5)" }} />}
                                        
                                    </div>
                                </Grid>
                            ))}
                            <Grid item style={{display: 'flex', flexDirection: 'column-reverse', paddingBottom: -6}}>
                                <span>Max</span>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }

        }
    }

    return (
        <Grid container style={{ marginTop: 16, marginBottom: 16 }}>
            <Grid style={{
                background: 'rgba(192, 192, 192, 0.2)',
                borderRadius: 10,
                padding: 16,

            }} container item xs={12}>
                <div style={{ display: 'flex', flex: 1, paddingBottom: 8, borderBottom: '1px solid rgba(192, 192, 192, 0.5)', marginBottom: 16 }}>
                    <QuestionTitle>{props.data.attributes.question}</QuestionTitle>
                    <PointText>{props.data.attributes.points} Points</PointText>
                </div>
                {
                    renderQuestionContent(props.data)
                }
                <Grid item xs={12} style={{marginTop: 16,}}>
                    <div>
                        <span style={{fontWeight: 600, fontFamily: 'Inter', color: 'black'}}>Feedback: </span>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: props.data.attributes.feedback}}>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

const QuestionTitle = styled(`span`)({
    flex: 1,
    fontFamily: 'Inter',
    fontSize: 16,
    color: 'black',
    fontWeight: 700,
})

const PointText = styled(`span`)({
    fontFamily: 'Inter',
    fontSize: 16,
    color: '#A046FB',
    fontWeight: 600
})

const StyledSelect = styled(Select)({
    width: '100%',
    minHeight: 50,
    paddingLeft: 16,
    paddingRight: 16,
    background: 'white',
    borderRadius: 5,
    '& .MuiSelect-select': {
        borderBottom: 'none', // Remove the underline
    },
    '& :focus': {
        background: 'white',
        backgroundColor: 'white'
    },
});

const MenuItemWithoutHover = styled(MenuItem)({
    '&:hover': {
        backgroundColor: 'transparent', // Disable hover effect,
        background: 'white'
    },
    '&:focus': {
        backgroundColor: 'transparent', // Disable hover effect,
        background: 'white'
    }
})

const NormalSlider = styled(Slider)({
    position: 'relative',
    height: 30,
    display: "flex",
    alignItems: "center",
    '& .MuiSlider-rail': {
        display: 'absolute', // Hide the rail,
        height: 16,
        borderRadius: 16,
        background: 'gray',
        backgroundColor: 'gray',
    },
    '& .MuiSlider-track': {
        background: '#A046FB',
        backgroundColor: '#A046FB',
        zIndex: 10,
        height: 16,
        maxHeight: 16,
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
    },
    '& .MuiSlider-thumb': {
        alignSelf: 'center',
        alignContent: 'center',
        marginTop: 0.3,
        height: 15.7,
        width: 15.7,
        color: '#A046FB'
    },
    // '& .MuiSlider-valueLabel': {
    //     display: 'none'
    // }
})

export const TabLabelText = styled(`span`)({
    color: (props: {selected: boolean}) => props.selected ? '#A046FB': "gray",
    fontFamily: 'Inter',
    fontWeight: (props: {selected: boolean}) => props.selected ? 700 : 500,
    fontSize: 16,
})

export const ReponseHeaderContainer = styled(`div`)({
    display: 'flex',
    marginBottom: 16,
})

export const HeaderCard = styled(Button)({
    border: (props: {selected: boolean}) => props.selected ? "none" : '1px solid gray',
    borderRadius: 20,
    textTransform: 'none',
    padding: 16,
    background: (props: {selected: boolean}) => props.selected ? "#A046FB" : "white",
    color: (props: {selected: boolean}) => props.selected ? "white" : "black",
    "&:hover": {
        border: (props: { selected: boolean }) => props.selected ? "none" : '1px solid gray',
        background: (props: { selected: boolean }) => props.selected ? "#A046FB" : "white",
    }
})